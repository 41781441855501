<template>
   <div class="card mb-0">
      <div class="card-body p-3 text-center cursor-pointer" @click="viewTicket">
         <h5 class="mb-2" :class="ticket.status == 'Aberto' ? 'text-danger' : ticket.status == 'Pendente avaliação' ? 'text-warning' : 'text-success'">{{ ticket.status }}</h5>
         <p class="m-0 font-13 limitador" v-if="ticket.status == 'Fechado'">
            <i class="fas fa-star-half-alt color-theme font-12 me-2"></i>Nota {{ ticket.rating }}
         </p>
         <p class="m-0 font-13 limitador">
            <i class="far fa-calendar color-theme font-12 me-2"></i><small class="text-secondary">Data:</small> {{ new Date(parseInt(ticket.date)).toLocaleDateString('pt-BR') }}
         </p>
         <p class="m-0 font-13 limitador" v-if="ticket.status != 'Fechado'">
            <i class="fas fa-long-arrow-right color-theme font-12 me-2"></i><small class="text-secondary">Descrição:</small> {{ ticket.title }} 
         </p>
      </div>
   </div>
</template>

<script>

export default {
	name: 'Ticket',
   props: ['ticket'],
   methods: {
      viewTicket : function () {
         this.$emit('viewTicket', this.ticket.id)
      }
   }
}

</script>