<template>
	<div class="row m-0" v-if="dadosUsuario.admin.usuarioIdJogador == null || dadosUsuario.admin.usuarioNomeJogador == null">
      <div class="col-12 py-5 text-center">É preciso ter <strong>ID Jogador</strong> registrado<br>para utilizar essa tela.</div>
   </div>

	<div class="row m-0" v-else>
      <div class="col-12 py-5 text-center" v-if="resultado.length == 0">Nenhum ticket encontrado</div>

      <div class="col-12 p-0" v-else>
         <div class="cards-2">
            <ticket v-for="(ticket, index) in resultado" :key="index" :ticket="ticket" @viewTicket="viewTicket($event)" />
         </div>
      </div>

      <!-- Modal mensagens -->
      <div class="modal fade" id="modalMsgsTicket">
         <div class="modal-dialog modal-md h-100 modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content h-100">
               <div class="modal-header">
                  <h4 class="modal-title" id="modalMsgsTicketLabel">Mensagens (Identidade {{ ticket.user_id }})</h4>
               </div>
               <div class="modal-body py-3">
                  <div id="mensagens">
                     <div class="row m-0">
                        <div class="col-12 p-0" v-for="(msg, index) in ticket.messages" :key="index">
                           <div class="card mb-2" :class="msg.user_id == dadosUsuario.admin.usuarioIdJogador ? 'minhaMensagem' : ''">
                              <div class="row m-0 font-12">
                                 <div class="col-12 p-0 nome">{{ msg.name }}</div>
                                 <div class="col-12 p-0">{{ msg.message }}</div>
                                 <div class="col-12 p-0 data">{{ new Date(parseInt(msg.date)).toLocaleString('pt-BR') }}</div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div id="mensagensChat" class="row m-0">
                     <div class="col ps-0 pe-1">
                        <textarea class="form-control shadow-none" placeholder="Mensagem..." v-model="ticketChat" @keyup.enter="sendMessage" maxlength="100"></textarea>
                     </div>
                     <div class="w-max-content p-0 align-self-center">
                        <button type="button" class="btn btn-primary px-3 py-2 mx-2" @click="sendMessage" :disabled="ticket.status == 'Aberto' && dadosUsuario.admin.usuarioIdJogador != null ? false : true">
                           <svg viewBox="0 0 24 24" width="20" height="20">
                              <path fill="currentColor" d="M1.101 21.757 23.8 12.028 1.101 2.3l.011 7.912 13.623 1.816-13.623 1.817-.011 7.912z"></path>
                           </svg>
                        </button>
                     </div>
                  </div>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-success me-2" @click="changeStatus">
                     <i class="far fa-check font-12 me-2"></i>Finalizar
                  </button>
                  <button type="button" class="btn btn-danger btn-simple waves-effect" data-bs-dismiss="modal">Fechar</button>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import $ from 'jquery'
import { mapState } from 'vuex'
import ticket from '@/components/admin/tickets/Ticket.vue'

export default {
	name: 'AdmTickets',
   data: function () {
      return {
         ticketChat: '',
         ticket: {},
         resultado: []
      }
   },
   computed: {
      ... mapState({
         listaPermissoes: state => state.listaPermissoes,
         dadosUsuario: state => state.dadosUsuario,
         logado: state => state.logado,
         urlRest: state => state.urlRest
      })
   },
   components: {
      ticket
   },
   methods: {
      getTickets : function () {
         let ref = this;
         this.$store.dispatch('alternarTelaCarregamento', true)
         this.resultado = []

         this.$axios({
            method: 'get',
            url: this.urlRest +'game/getTickets',
            headers: {
               'Authorization': 'Bearer ' + this.logado.token +'@@@'+ this.dadosUsuario.admin.usuarioUsername 
            }
         }).then(response => {
				this.resultado = response.data

         }).catch(function (error) {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  ref.$store.dispatch('deslogarAdmin')

                  ref.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else {
                  ref.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               }
            } else {
               ref.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
      viewTicket : function (id) {
         let ref = this;
         this.$store.dispatch('alternarTelaCarregamento', false)
         this.ticket = {}

         this.$axios({
            method: 'get',
            url: this.urlRest +'game/getTicket',
            headers: {
               'Authorization': 'Bearer ' + this.logado.token +'@@@'+ this.dadosUsuario.admin.usuarioUsername 
            },
            params: {
               id: id
            }
         }).then(response => {
				this.ticket = response.data

         }).catch(function (error) {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  ref.$store.dispatch('deslogarAdmin')

                  ref.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else {
                  ref.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               }
            } else {
               ref.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            $('#modalMsgsTicket').modal('show')
            this.$store.dispatch('alternarTelaCarregamento', false)
            
            $("#modalMsgsTicket").on('shown.bs.modal', function () {
               $('#mensagens').animate({scrollTop:$('#mensagens .row').height()}, 0)
               $("#mensagensChat textarea").focus()
            })
         });
      },
      changeStatus : function () {
         let ref = this;
         this.$store.dispatch('alternarTelaCarregamento', true)

         this.$axios({
            method: 'post',
            url: this.urlRest +'game/changeStatus',
            headers: {
               'Authorization': 'Bearer ' + this.logado.token +'@@@'+ this.dadosUsuario.admin.usuarioUsername
            },
            params:{
               id: this.ticket.id,
               status: 'Pendente avaliação'
            }
         }).then(function () {
            ref.resultado.forEach(t => {
               if (t.id == ref.ticket.id) {
                  t.status = 'Pendente avaliação';
               }
            });

            $('#modalMsgsTicket').modal('hide')

            ref.$toast.fire({
               icon: 'success',
               title: 'Ticket finalizado!'
            })
         }).catch(function (error) {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  ref.$store.dispatch('deslogarAdmin')

                  ref.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else {
                  ref.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  })
               }
            } else {
               ref.$toast.fire({
                  icon: 'error',
                  title: error
               })
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         })
      },
      sendMessage : function () {
         if (this.ticket.status == 'Aberto' && this.dadosUsuario.admin.usuarioIdJogador != null && this.dadosUsuario.admin.usuarioNomeJogador != null) {
            if (String(this.ticketChat).trim().length == 0) {
               return
            }

            let ref = this;
            this.$store.dispatch('alternarTelaCarregamento', true)
   
            this.$axios({
               method: 'post',
               url: this.urlRest +'game/sendMessage',
               headers: {
                  'Authorization': 'Bearer ' + this.logado.token +'@@@'+ this.dadosUsuario.admin.usuarioUsername
               },
               params: {
                  idTicket: this.ticket.id,
                  idPlayer: this.dadosUsuario.admin.usuarioIdJogador,
                  message: String(this.ticketChat).trim()
               }
            }).then(function () {
               let agora = new Date()
   
               ref.ticket.messages.push({
                  "user_id": ref.dadosUsuario.admin.usuarioIdJogador,
                  "name": ref.dadosUsuario.admin.usuarioNomeJogador,
                  "message": ref.ticketChat,
                  "date": agora.getHours() +":"+ agora.getMinutes() +" "+ agora.getDate() +"/"+ (agora.getMonth() + 1) +"/"+ agora.getFullYear()
               })

               setTimeout(() => {
                  $('#mensagens').animate({scrollTop:$('#mensagens .row').height()}, 0)
               }, 100);
            }).catch(function (error) {
               if (error.response != undefined) {
                  if (error.response.status == 408) {
                     ref.$store.dispatch('deslogarAdmin')
   
                     ref.$toast.fire({
                        icon: 'warning',
                        title: 'Sessão expirada!'
                     });
                  } else {
                     ref.$toast.fire({
                        icon: 'error',
                        title: 'Erro: ' + error.response.status
                     })
                  }
               } else {
                  ref.$toast.fire({
                     icon: 'error',
                     title: error
                  })
               }
            }).finally(() => {
               this.ticketChat = '';
               this.$store.dispatch('alternarTelaCarregamento', false)
            })
         } else {
            this.$toast.fire({
               icon: 'error',
               title: 'Ticket fechado!'
            });
         }
      }
   },
   mounted() {
      this.getTickets()
   }
}

</script>

<style scoped>

#mensagens {
	overflow-y: auto;
	height: 80%;
}

#mensagens .card {
	max-width: 50%;
	width: max-content;
	padding: 12px 16px;
	border-radius: 24px;
	border-top-left-radius: 0px;
	box-shadow: 0 1px 2px 0 #eee;
	border: 1px solid #eee;
}

#mensagens .minhaMensagem {
	float: right;
	background-color: #D1D7E0 !important;
	color: #000;
	border-radius: 24px !important;
	border-top-right-radius: 0px !important;
}

#mensagens .nome {
	font-weight: 700;
	margin-bottom: 4px;
	overflow: hidden;
   display: -webkit-box;
   -webkit-line-clamp: 1;
   -webkit-box-orient: vertical;
	line-break: anywhere;
}

#mensagens .data {
	font-size: 10px;
	text-align: right;
	color: #777;
	overflow: hidden;
   display: -webkit-box;
   -webkit-line-clamp: 1;
   -webkit-box-orient: vertical;
	line-break: anywhere;
}

#mensagensChat {
	height: 20%;
}

#mensagensChat button {
	height: auto !important;
}

#mensagensChat textarea {
	border: 1px solid #eee !important;
	height: 100% !important;
	width: 100%;
   font-size: 14px;
}
	
#mensagensChat textarea:focus {
	border-color: #ddd !important; 
}

</style>